import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

import * as styles from './contactForm.module.css';

function ContactForm() {
	const [state, handleSubmit] = useForm('mrgonkqa');
	if (state.succeeded) {
		return <p>Thanks for reaching out!</p>;
	}
	return (
		<div className={styles.container}>
			<form onSubmit={handleSubmit}>
				<label htmlFor='name'>Name</label>
				<input
					id='Name'
					type='text'
					name='name'
					className={styles.input}
				/>
				<ValidationError
					prefix='Name'
					field='name'
					errors={state.errors}
				/>
				<label htmlFor='email'>Email Address</label>
				<input
					id='email'
					type='email'
					name='email'
					className={styles.input}
				/>
				<ValidationError
					prefix='Email'
					field='email'
					errors={state.errors}
				/>
				<label htmlFor='subject'>Subject</label>
				<input
					id='subject'
					type='text'
					name='subject'
					className={styles.input}
				/>
				<ValidationError
					prefix='Subject'
					field='subject'
					errors={state.errors}
				/>
				<label htmlFor='message'>Message</label>
				<textarea
					id='message'
					name='message'
					placeholder='Write your email here'
					className={styles.input}
				/>
				<ValidationError
					prefix='Message'
					field='message'
					errors={state.errors}
				/>
				<button type='submit' disabled={state.submitting}>
					Submit
				</button>
			</form>
		</div>
	);
}

export default ContactForm;
