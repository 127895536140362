import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/layout';
import ContactForm from '../components/contactPage/contactForm';

import * as styles from './contact.module.css';

const ContactPage = () => {
	const {
		backgroundImage: {
			file: { url: backgroundURL },
		},
		contactBlurb: {
			childMarkdownRemark: { html: contactBlurbHTML },
		},
	} = useStaticQuery(graphql`
		{
			contentfulContactPage {
				backgroundImage {
					file {
						url
					}
				}
				contactBlurb {
					childMarkdownRemark {
						html
					}
				}
			}
		}
	`).contentfulContactPage;

	return (
		<Layout>
			<div
				className={styles.splash}
				style={{
					background: `url(${backgroundURL})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<article className={styles.content}>
					<h1>Contact Me</h1>
					<div
						dangerouslySetInnerHTML={{
							__html: contactBlurbHTML,
						}}
					></div>
				</article>
				<ContactForm />
			</div>
		</Layout>
	);
};

export default ContactPage;
